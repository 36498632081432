import React, { useEffect, useState }  from "react"
import Head from '../components/head'
import Layout from "../components/layout"
import Lotti404Mobile  from '../components/lottie/Lottie404_mobile'
import Lotti404Desktop  from '../components/lottie/Lottie404_desktop'
import * as animationData from '../../static/assets/lotties/404.json'
import { ButtonSquare } from '../components/buttons/_button_square'
import { navigate } from 'gatsby'
import styles from '../styles/pages/404.module.scss'
import {DEVICES,useCurrenDevice} from '../hooks/useCurrentDevice'


const NotFoundPage = () => {

  const [device] = useCurrenDevice()
  const [isMobile, setIsMobile] = useState(null)

  let metas= {
    title:'404',
    description: 'You just hit a route that doesn&#39;t exist',
    image:''
  } 

  useEffect(() =>{
    document.getElementsByClassName('main')[0].classList.add(styles.main)
    document.getElementById('header__container').classList.add(styles.header404)
    
    return () =>{
      document.getElementsByClassName('main')[0].classList.remove(styles.main)
      document.getElementById('header__container').classList.remove(styles.header404)
    }
  },[])

  useEffect(() =>{
    if(device == DEVICES.desktop){
      setIsMobile(false)
      // console.log('desktop')
    }else {
      setIsMobile(true)
      // console.log('mobile')
    }
  },[device])
  
 return (
          <Layout>
            <Head
              title={metas.title}
              pathname={`/comparador`}
              description={metas.description}
              image={metas.image}/>
            <div className={styles.container}>
              {
                isMobile ? 
                          <Lotti404Mobile classLottieContainer={styles.lottieContainer} animation={animationData} isStopped={false} isPaused={false}/>
                         :
                          <Lotti404Desktop classLottieContainer={styles.lottieContainer} animation={animationData} isStopped={false} isPaused={false}/>
              }
               
              <div className={styles.desc_container}>
                <p className={styles.desc}>
                  Parece que no podemos encontrar la página que estás buscando.
                </p>
                <ButtonSquare
                  _bg_color='#FF4056'
                  _box_shadow_rgba='255,64,86'
                  _text='Home'
                  _onClick={ () => navigate('/') }
                  _className={styles.home_button}/>
              </div>
            </div>
          </Layout>
  )
}

export default NotFoundPage
