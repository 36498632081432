import React from 'react'
import Lottie from 'react-lottie';

const Lottie404Desktop = ({isStopped, isPaused, classLottieContainer, animation}) => {
 
  const defaultOptionsDesktop = {
    loop: true,
    autoplay: true, 
    animationData: animation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMax slice'
    }
  }

  return  <div className={`${classLottieContainer} lottie___desktop`}>
            <Lottie options={defaultOptionsDesktop}
                          // height={heigth ? heigth : `100vh`}
                          // width={width ? width : `100vw`}
                          height={`100%`}
                          width={`100%`}
                          isStopped={isStopped}
                          isPaused={isPaused}/>
          </div>
  
}

export default Lottie404Desktop  