import React from 'react'
import Lottie from 'react-lottie';

const Lottie404Mobile = ({isStopped, isPaused, classLottieContainer, animation}) => {

  const defaultOptionsMobile = {
    loop: true,
    autoplay: true, 
    animationData: animation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return  <div className={`${classLottieContainer} lottie___mobile`}>
            <Lottie options={defaultOptionsMobile}
                          // height={heigth ? heigth : `100vh`}
                          // width={width ? width : `100vw`}
                          height={`100%`}
                          width={`100%`}
                          isStopped={isStopped}
                          isPaused={isPaused}/>
          </div>
  
}

export default Lottie404Mobile  